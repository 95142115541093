import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/HeaderConference';
import Footer from '../Footer/Footer';


function ConferenceBooking() {

    //const createUserApi = "http://localhost/eventapi/index.php/auth/visitorDataSave"
    //const createUserApi = "http://localhost:3002/visitorregistration"
    const createUserApi = `${process.env.REACT_APP_API_URL}auth/conferenceDataSave`
    const navigate = useNavigate();
    const [error, setError] = useState([]);
    const [passtotal,setTotal]=useState([]);
    const [isClicked, setIsClicked] = useState(false);


  

   
    useEffect(() => {
        document.title = "Conference Booking - Auto EV Expo";
    }, []);


   
   

    // name, jobprofile, companyname,phone,email,city,referby,message
    const [user, setUser] = useState([{
        passtype: "",
        bookingday: "",
        name: "",
        designation: "",
        companyname: "",
        gstno:"",
        cityname: "",
        phone: "",
        email: "",
        vtot:0
    }]);


    const handleinputchange=(e, index)=>{
        e.preventDefault();
        const {name, value}= e.target;
        console.log(name, value);
         


        const list= [...user];
        list[index][name]= value;
       
       
        let passval=document.getElementsByName("passtype")[index].value;
       // e.target.name=='passtype'?document.getElementsByName("bookingday")[index].value=='':document.getElementsByName("bookingday")[index].value;
       if(e.target.name=='passtype')
       {
        document.getElementsByName("bookingday")[index].value='';
       }
        
        if(!document.getElementsByName("bookingday")[index].value)
        {
            list[index].vtot=0;
        }
        switch (e.target.value) {
            case "Day 1 & 2 (27-Nov-2024 to 28-Nov-2024)":
               // passval=='Prime'?setTotal(passtotal+2500):setTotal(passtotal+750);
                if(passval=='C Level')
                {
                    list[index].vtot=10000;
                }
                else
                {
                    passval=='Prime'? list[index].vtot=2500:list[index].vtot=750;
                }
                
              break;
            case "Day 1 (27-Nov-2024)":
                   if(passval=='C Level')
                    {
                        list[index].vtot=5000;
                    }
                    else
                    {
                      passval=='Prime'?list[index].vtot=1500:list[index].vtot=400;
                    }
              break;
              case "Day 2 (28-Nov-2024)":
                if(passval=='C Level')
                    {
                        list[index].vtot=5000;
                    }
                    else
                    {
                       passval=='Prime'?list[index].vtot=1500:list[index].vtot=400;
                    }
              break; 
          }
          setUser(list);
          console.log(list);
          let total = list.reduce((previousValue, currentValue) => {
            return previousValue + currentValue.vtot;
          }, 0);

          setTotal(total);

        // switch (e.target.value) {
        //     case "Day 1 & 2(27-Nov-2024 to 28-Nov-2024)":
        //         setTotal(750);
        //       break;
        //     case "Business":
        //         setTotal(400);
        //       break;
        //   }
       // sumtotal();
      }


    /*const handelInput = (event,index) => {
        event.preventDefault();
        const { name, value } = event.target;
        console.log(name, value)
        setUser({ ...user, [name]: value });
    }*/


    const validateForm = () => {
        let isValid = true;
        const newErrors = [];

        // Validate password
        // for(index=0;index >user.length; index++)
        // if (!user[index].name) {
        //     newErrors.name = "Full Name is required";
        //     isValid = false;
        // }
        // if (!user[index].companyname) {
        //     newErrors.companyname = "Job Profile is required";
        //     isValid = false;
        // }
        
        /*
        if (!user.companyname) {
            newErrors.companyname = "Company Name is required";
            isValid = false;
        }

        if (!user.phone) {
            newErrors.phone = "Mobile Number is required";
            isValid = false;
        }
        else if (user.phone.length != 10) {
            newErrors.phone = "Mobile Number is inValid";
            isValid = false;
        }


        if (!user.email) {
            newErrors.email = "Email Address is required";
            isValid = false;
        }
        if (!user.cityname) {
            newErrors.cityname = "City Name is required";
            isValid = false;
        }
        if (!user.passtype) {
            newErrors.passtype = "Refrence By is required";
            isValid = false;
        }
        if (!user.bookingday) {
            newErrors.bookingday = "Refrence By is required";
            isValid = false;
        }*/


        setError(newErrors);
        return isValid;
    };


   


    const handelSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) {

            //console.log(error);
        }
        else {
            //console.log(user)
            try {
                setIsClicked(true);
                const response = await fetch(createUserApi, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(user),
                });

                if (response.ok) {
                    const data = await response.json();
                    setIsClicked(true);
                    console.log('Form submitted successfully!');
                    console.log(data);
                    console.log(data.results.bookingid);
                    const dataparam={bookingid:data.results.bookingid,user,totalamount:passtotal};
                    navigate('/PayAmount',{state:dataparam});
                    // setUser({
                    //     passtype: "",
                    //     bookingday: "",
                    //     name: "",
                    //     designation: "",
                    //     companyname: "",
                    //     cityname: "",
                    //     phone: "",
                    //     email: ""
                    // })
                    // navigate('/show-user');
                } else {
                    console.error('Form submission failed!');
                }

            } catch (error) {
                setError(error.message);
            } finally {

            }
        }
    }

    const handleremove = index => {
        const list = [...user];
        list.splice(index, 1);
        setUser(list);
        let total = list.reduce((previousValue, currentValue) => {
            return previousValue + currentValue.vtot;
          }, 0);

          setTotal(total);

    }

    const handleaddclick = () => {
        setUser([...user, {
            passtype: "",
            bookingday: "",
            name: "",
            designation: "",
            companyname: "",
            gstno:"",
            cityname: "",
            phone: "",
            email: "",
            vtot:0
        }]);
    }


    return (
        <div>
            <Header />
            <div className="d-flex flex-column ms-5">
                <div className='container'>
                  
                        <div className='row'>
                        <div className='col-md-12'>
                            <div className='heading mr-20'>
                                <h1>Conference Booking</h1>
                            </div>
                            <form onSubmit={handelSubmit}>
                                <div>
                                    {
                                        user.map((x, i) => {
                                            return (<>
                                            <div className='shadow-sm p-3 mb-5 bg-light rounded '>
            <div className="row ">
    <div className="col mb-3">
    <label for="passtype" className="form-label required">Pass Type</label>
    <select className="form-control form-select" id="passtype" name="passtype"   onChange={ e=>handleinputchange(e,i)}>
                     <option value="">--Select--</option>
                     <option value="Business">Business</option>
                     <option value="Prime">Prime </option>
                     <option value="C Level">C Level </option>
                    </select>
                    {error.passtype && <div className="error">{error.passtype}</div>}
    </div>
    <div class="col mb-3">
    <label for="name" className="form-label required">Booking Day</label>
    <select className="form-control form-select" id="bookingday" name="bookingday"   onChange={ e=>handleinputchange(e,i)} >
                     <option value="">--Select--</option>
                     <option value="Day 1 (27-Nov-2024)">Day 1 (27-Nov-2024)</option>
                     <option value="Day 2 (28-Nov-2024)">Day 2 (28-Nov-2024) </option>
                     <option value="Day 1 & 2 (27-Nov-2024 to 28-Nov-2024)">Day 1 & 2 (27-Nov-2024 to 28-Nov-2024) </option>
                    </select>
                    {error.bookingday && <div className="error">{error.bookingday}</div>}
    </div>
    <div class="col mb-3">
    <label for="companyname" className="form-label required">Company Name</label>
      <input type="text" id="companyname"  class="form-control" placeholder="Enter Company Name" required name="companyname" value={x.companyname}   onChange={ e=>handleinputchange(e,i)}/>
      {error.companyname && <div className="error">{error.companyname}</div>}
    </div>
  </div>

  <div class="row">
    <div class="col mb-3">
    <label for="name" className="form-label required">Name</label>
      <input type="text" class="form-control" placeholder="Enter Full Name" name="name" required value={x.name}   onChange={ e=>handleinputchange(e,i)}/>
      {error.name && <div className="error">{error.name}</div>}
    </div>

    <div class="col mb-3">
    <label for="phone" className="form-label required">Mobile No</label>
      <input type="text" id="phone" class="form-control" placeholder="Enter Mobile No" required name="phone" value={x.phone}   onChange={ e=>handleinputchange(e,i)}/>
      {error.phone && <div className="error">{error.phone}</div>}
    </div>
    <div class="col mb-3">
    <label for="email" className="form-label required">EMail Id</label>
      <input type="email" id="email" class="form-control" placeholder="Enter EMail" required name="email" value={x.email}   onChange={ e=>handleinputchange(e,i)}/>
      {error.email && <div className="error">{error.email}</div>}
    </div>
  
  </div>

  <div class="row">
    
  <div class="col mb-3">
    <label for="designation" className="form-label required">Designation / Job Title</label>
      <input type="text" class="form-control" id="designation" placeholder="Enter Designation" required name="designation" value={x.designation}   onChange={ e=>handleinputchange(e,i)}/>
      {error.designation && <div className="error">{error.designation}</div>}
    </div>

    <div class="col mb-3">
    <label for="cityname" className="form-label required">City Name</label>
      <input type="text" id="cityname" class="form-control" placeholder="Enter City Name" required name="cityname" value={x.cityname}   onChange={ e=>handleinputchange(e,i)}/>
      {error.cityname && <div className="error">{error.cityname}</div>}
    </div>
    {i==0 ? <>
    <div class="col mb-3">
    <label for="gstno" className="form-label">GST NO.</label>
      <input type="text" id="gstno"  class="form-control" placeholder="Enter GST NO." name="gstno" value={x.gstno}   onChange={ e=>handleinputchange(e,i)}/>
    </div>
    </>:''}
  </div>

 
  <div className='row'>
  <div className="form-group col-md-8 mt-4"></div>

  <div className="form-group col-md-4 mt-4">
               {
                  user.length!==1 &&
                  <button  className="btn btn-danger mx-1  float-end" onClick={()=> handleremove(i)}>Remove</button>
               }
               &nbsp;&nbsp;&nbsp;
               { user.length-1===i &&
               <button  className="btn btn-success float-end" onClick={ handleaddclick}>Add More</button>
               }
               </div></div></div>
                                            </>);
                                        })}
                                </div>
                                { passtotal!=0?<>
                                <div><b>No Of Tickets:</b> {user.length} </div>
                                <div><b>Total Price:</b> {passtotal*2}  INR</div>
                                <div><b> Price After Discount:</b> {passtotal} INR</div>
                                <div><b>GST (18%):</b> {passtotal*18/100} INR</div>
                                <div><b>Total Payble Amount:</b> {passtotal+(passtotal*18/100)} INR</div><br/>
                                <button type="submit" disabled={isClicked} className="btn btn-primary submit-btn">Pay Now</button>
                                </>:''}   


                                 {isClicked?<>
                                <div><h3>Loading....</h3></div></>:''}                             
                            </form>
                            {/* {<p>{JSON.stringify(user)}</p> } 
                            {<p>{JSON.stringify(passtotal)}</p> }  */}
                        </div>
                    </div>
                </div>

            </div>
            <div className='container-fluid  bg-green mr-20'>
            <Footer />
            </div>

        </div>
    )
}

export default ConferenceBooking;